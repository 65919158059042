import React, { useContext, useState } from "react";
import { Animated } from "react-animated-css";
import { Row, Col, Empty } from "antd";
import Logo from "./../../assets/logo-bg.png";
import Emty from "./../../assets/emtyorder.svg";
import Emty2 from "./../../assets/emty2.svg";
import { AuthContext } from "./../../context/AuthProvider";
import Helper from "./../../extra/helper";
import Confirm from "./modal/confirm";
import Otp from "./modal/otp";
import Succes from "./modal/succes";

export default function Content() {
  const { arrSearch, animation, setData } = useContext(AuthContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [otp, setOpenOtp] = useState(false);
  const [openSucces, setOpenSucces] = useState(false);

  const handCancel = () => {
    setOpenConfirm(false);
    setOpenOtp(false);
    setOpenSucces(false);
  };

  const css = {
    item: {
      fontSize: 15,
      textAlign: "left",
      color: "#232323",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginTop: 10,
    },
  };

  return (
    <>
      {openSucces && (
        <Succes
          setOpenSucces={setOpenSucces}
          open={openSucces}
          handCancel={handCancel}
        />
      )}
      {openConfirm && (
        <Confirm
          setOpenOtp={setOpenOtp}
          open={openConfirm}
          handCancel={handCancel}
        />
      )}
      {otp && <Otp open={otp} handCancel={handCancel} setOpenSucces={setOpenSucces} />}
      {(!arrSearch || arrSearch?.length === 0) && (
        <Row>
          <Col xs={24}>
            <Animated
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={true}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "55vh",
                  width: "100%",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    style={{ height: 150, marginBottom: 7 }}
                    src={!arrSearch ? Emty2 : Emty}
                    alt="emty"
                  />
                  {arrSearch?.length === 0 && (
                    <div>
                      Không có thông tin tìm kiếm!
                    </div>
                  )}
                </div>
              </div>
            </Animated>
          </Col>
        </Row>
      )}
      {arrSearch && arrSearch?.length > 0 && (
        <Row style={{ maxWidth: 1500, margin: "0px auto" }}>
          <Col xl={24} style={{ padding: "1% 5%", marginTop: "1%" }}>
            {/* <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} isVisible={animation}> */}
            <h5 style={{ fontSize: 20, color: "#222222", fontWeight: "bold" }}>
              Đã tìm thấy {arrSearch?.length} đơn hàng
            </h5>
            {/* </Animated> */}
          </Col>
          <Row style={{ width: "100%", padding: "0 5%", minHeight: "49vh" }}>
            {(arrSearch || []).map((data, i) => (
              <Col
                key={i}
                xl={6}
                lg={6}
                sm={12}
                xs={24}
                style={{ paddingRight: "2%", marginBottom: "2%" }}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationOut="fadeOutLeft"
                  animationInDuration={300 + i * 200}
                  animationOutDuration={1000}
                  isVisible={animation}
                  style={{ height: "100%" }}
                >
                  {/* Hiển thị cho bảo hiểm nhà dùng thử */}
                  {+data.productId === 57 && (
                    <div
                      className="order-grid"
                      style={{
                        border: "2px solid #e8ecec",
                        width: "100%",
                        borderRadius: 8,
                        textAlign: "center",
                        padding: "4%",
                        transition: "all 0.4s ease 0s",
                        minHeight: 300,
                        display: "block",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "70px", margin: "20px 0px" }}
                        src={data?.productImage ? data?.productImage : Logo}
                        alt="Logo"
                      />
                      <div
                        style={{
                          fontSize: 18,
                          textAlign: "center",
                          color: "#232323",
                        }}
                      >
                        BH {data.productTitle}
                      </div>
                      <div style={css.item}>
                        <span style={{ color: "#646D69" }}>Mã đơn: </span>{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {data.code}
                        </span>
                      </div>
                      <div style={css.item}>
                        <span style={{ color: "#646D69" }}>Chủ hợp đồng:</span>{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {data?.insuredInfo?.insuredName || data?.buyerName}
                        </span>
                      </div>
                      <div style={css.item}>
                        <span style={{ color: "#646D69", minWidth: 65 }}>
                          Thời hạn:{" "}
                        </span>{" "}
                        <span
                          style={{
                            color: Helper._checkEndate(data.endDate)
                              ? "red"
                              : "",
                            textAlign: "right",
                          }}
                        >
                          {Helper._formatDay(data.beginDate)} -{" "}
                          {Helper._formatDay(data.endDate)}
                        </span>
                      </div>
                      <div style={{...css.item}}>
                        <span style={{ color: "#646D69", minWidth: 120}}>
                          Số tiền bảo hiểm:{" "}
                        </span>{" "}
                        <span style={{textAlign:'right'}}>
                          {Helper._formatMoney(
                            data?.insuredInfo?.houseInfo?.value
                          )}{" "}
                          VNĐ
                        </span>
                      </div>
                      <div style={css.item}>
                        <span style={{ color: "#646D69" }}>phí bảo hiểm: </span>{" "}
                        <span>
                          {Helper._formatMoney(data?.insuredInfo?.feesPayment)}{" "}
                          VNĐ
                        </span>
                      </div>
                      <div style={css.item}>
                        <span style={{ color: "#646D69", minWidth: 120 }}>
                          Ngày thanh toán:{" "}
                        </span>{" "}
                        <span
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {Helper._formatDay(data.createdDate)}
                        </span>
                      </div>
                      <div>
                        <div
                          style={{
                            width: "100%",
                            height: data?.certLink ? 1 : 36,
                            background: data?.certLink ? "#e8ecec" : "#ffffff",
                            margin: "3% 0px",
                            display: !Helper._checkEndate(data.endDate)
                              ? ""
                              : "none",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <a href={data?.certLink} target={"_blank"}>
                            <div
                              className="buttonView"
                              style={{
                                fontSize: 13,
                                color: "#1A437E",
                                border: "1px solid #1A437E",
                                borderRadius: 20,
                                padding: "6px 14px",
                                margin: "0px auto",
                                // width: 120,
                                cursor: "pointer",
                                fontWeight: 500,
                                display:
                                  data?.certLink &&
                                  !Helper._checkEndate(data.endDate)
                                    ? ""
                                    : "none",
                              }}
                            >
                              XEM GCN
                            </div>
                          </a>
                          {/* Khi là chiến dịch dùng thử thì hiện lên */}
                          {data?.insuredInfo?.isCampaign === 1 &&
                            data?.insuredInfo?.isTrial === 1 &&
                            data?.status === 6 && (
                              <div
                                className="buttonView"
                                onClick={() => {
                                  setOpenConfirm(true);
                                  setData(data);
                                }}
                                style={{
                                  fontSize: 13,
                                  color: "#FFF",
                                  border: "1px solid #1A437E",
                                  borderRadius: 20,
                                  background: "#1A437E",
                                  padding: "6px 14px",
                                  // margin: "0px auto",
                                  // width: 120,
                                  cursor: "pointer",
                                  fontWeight: 500,
                                  display:
                                    data?.certLink &&
                                    !Helper._checkEndate(data.endDate)
                                      ? ""
                                      : "none",
                                }}
                              >
                                TẠO YÊU CẦU HUỶ
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  {+data.productId !== 57 && (
                    <div
                      className="order-grid"
                      style={{
                        border: "2px solid #e8ecec",
                        width: "100%",
                        borderRadius: 8,
                        textAlign: "center",
                        padding: "4%",
                        transition: "all 0.4s ease 0s",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <img
                        style={{ height: "70px", margin: "20px 0px" }}
                        src={data?.productImage ? data?.productImage : Logo}
                        alt="Logo"
                      />
                      <div
                        style={{
                          fontSize: 18,
                          textAlign: "left",
                          color: "#232323",
                        }}
                      >
                        BH {data.productTitle}
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          textAlign: "left",
                          color: "#232323",
                        }}
                      >
                        <span>Mã đơn:</span>{" "}
                        <span style={{ textTransform: "uppercase" }}>
                          {data.code}
                        </span>
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          textAlign: "left",
                          color: "#fb236a",
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.insuredInfo?.insuredName || data?.buyerName}
                      </div>
                      <div
                        style={{
                          fontSize: 13,
                          color: Helper._checkEndate(data.endDate)
                            ? "red"
                            : "#888888",
                          textAlign: "left",
                          marginBottom: "70px",
                        }}
                      >
                        <span style={{ color: "#888888" }}>Thời hạn:</span>{" "}
                        {Helper._formatDay(data.beginDate)} -{" "}
                        {Helper._formatDay(data.endDate)}
                      </div>
                      <div
                        style={{
                          position: "fixed",
                          bottom: 0,
                          width: "90%",
                          height: 62,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: data?.certLink ? 1 : 36,
                            background: data?.certLink ? "#e8ecec" : "#ffffff",
                            margin: "3% 0px",
                            display: !Helper._checkEndate(data.endDate)
                              ? ""
                              : "none",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <a href={data?.certLink} target={"_blank"}>
                            <div
                              className="buttonView"
                              style={{
                                fontSize: 13,
                                color: "#1A437E",
                                border: "1px solid #1A437E",
                                borderRadius: 20,
                                padding: "6px 14px",
                                margin: "0px auto",
                                // width: 120,
                                cursor: "pointer",
                                fontWeight: 500,
                                display:
                                  data?.certLink &&
                                  !Helper._checkEndate(data.endDate)
                                    ? ""
                                    : "none",
                              }}
                            >
                              XEM GCN
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </Animated>
              </Col>
            ))}
          </Row>
        </Row>
      )}
    </>
  );
}
