import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import OtpInput from "react-otp-input";
import { AuthContext } from "./../../../context/AuthProvider";
import Service from './../../../extra/getAPI';

const OTP = (props) => {
  const { data } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [time, setTime] = useState(120);
  const [disa, setDisa] = useState(true);
  const [loading, setLoading] = useState(false);


    // nhập otp và huỷ
    const Cancel = (otp) => {
      setLoading(true);
      const data2 =JSON.stringify({
        otp : +otp,
        reason : data?.note
      })
      Service.apicalCore('POST', `/api/v1/product/private-home/campaign/${data?.code}/cancel`,data2).then((res) => {
        setLoading(false);
        let data = res?.data;
        if(data?.statusCode === 200){
          props.handCancel();
          props.setOpenSucces(true);
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err);
      }
      );
    }

  // lấy lại otp
  const requestCancel = () => {
    setLoading(true);
    Service.apicalCore('POST', `/api/v1/product/private-home/campaign/${data?.code}/request-cancel`).then((res) => {
      setLoading(false);
      let data = res?.data;
      if(data?.statusCode === 200){
        setTime(120);
        setDisa(true);
        setOtp("")
      }
    }).catch((err) => {
      setLoading(false);
      console.log(err);
    }
    );
  }

  useEffect(() => {
    Countdown(time);
  }, [time]);

  const Countdown = (secs) => {
    if (secs > 0) {
      setTimeout(() => {
        setTime(secs - 1);
      }, 1000);
    }
  };

  const getOtp = (value) => {
    if (value.length === 6) {
      setDisa(false);
    } else {
      setDisa(true);
    }
    setOtp(value);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14 }}
        footer={false}
        closable={true}
        width={550}
        open={props.open}
        onCancel={props.handCancel}
        title={""}
      >
        <div>
          <div style={{ fontSize: 28, fontWeight: 500, textAlign: "center" }}>
            Xác thực mã OTP để gửi yêu cầu huỷ
          </div>
          <div style={{ fontSize: 16, textAlign: "center" }}>
            Vui lòng nhập mã OTP đã được gửi tới địa chỉ email
            <br />{" "}
            <b>
              {data?.buyerEmail?.slice(0, 4)}******
              {data?.buyerEmail?.slice(-4)}
            </b>{" "}
            của bạn.
          </div>
          <div>
            <div
              style={{
                textAlign: "center",
                marginTop: 15,
                fontSize: 16,
                color: "#39B44A",
              }}
            >
              Mã OTP sẽ hết hạn trong vòng{" "}
              <b style={{ color: "#0B3C8A" }}>{time}</b> giây
            </div>
          </div>
          <div>
            <OtpInput
              inputType={"tel"}
              value={otp}
              onChange={(e) => getOtp(e)}
              numInputs={6}
              renderInput={(props) => <input id="otpCustomm" {...props} />}
              inputStyle={{
                width: "100%",
                fontSize: 25,
                marginRight: "2%",
                border: "none",
                // borderBottom: "2px solid #DCDCDC",
                boxShadow: "0px 0px 8px 0px #00000040",
                marginTop: 15,
                borderRadius: 7,
                color: "#0B3C8A",
              }}
            />
          </div>
          <div xs={24}>
            <div style={{ textAlign: "center", marginTop: 15, cursor:'pointer'}}>
              {/* Bạn chưa nhận được mã xác thực? <br /> */}
              <b
                onClick={() => requestCancel()}
                style={{ color: "#0B3C8A", display: time === 0 ? "" : "none" }}
              >
                Gửi lại mã OTP
              </b>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <Button
              disabled={disa}
              onClick={()=>Cancel(otp)}
              loading={loading}
              style={{
                background: disa ?"#ddd" : "#0B3C8A",
                color: "#fff",
                textAlign: "center",
                borderRadius: 7,
                cursor: "pointer",
                height: 50,
                width: 120,
              }}
            >
              <b>Xác nhận</b>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OTP;
