import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Input, Form } from "antd";
import Service from "./../../../extra/getAPI";
import { AuthContext } from "./../../../context/AuthProvider";

const Confirm = (props) => {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { data, setData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // lấy otp
  const requestCancel = () => {
    setLoading(true);
    Service.apicalCore(
      "POST",
      `/api/v1/product/private-home/campaign/${data?.code}/request-cancel`
    )
      .then((res) => {
        setLoading(false);

        let data = res?.data;
        if (data?.statusCode === 200) {
          props.handCancel();
          props.setOpenOtp(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        setData({ ...data, note: values.note });
        requestCancel(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14 }}
        footer={false}
        closable={true}
        open={props.open}
        onCancel={props.handCancel}
        title={<b>Xác nhận</b>}
      >
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          <div>
            <div style={{ textAlign: "justify" }}>
              Hiệu lực bảo hiểm của bạn sẽ bị chấm dứt, nhà bảo hiểm sẽ không
              chịu trách nhiệm bồi thường cho các rủi ro xảy ra khi bạn thực
              hiện yêu cầu huỷ CNBH này. Bạn đã chắc chắn muốn huỷ?
            </div>
            <div style={{ marginTop: 10 }}>
              <b>
                Vui lòng nhập lý do yêu cầu hủy đơn bảo hiểm của bạn
                <span style={{ color: "red" }}>*</span>
              </b>
              <Form.Item
                style={{ marginTop: 10 }}
                name="note"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập đầy đủ thông tin.",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={true}
                  allowClear={false}
                  placeholder="Nhập lý do..."
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <Button
                onClick={() => props.handCancel()}
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#2D9AFF",
                  borderWidth: 0,
                  boxShadow:"none"
                }}
                color="#ED5A33"
              >
                Đóng
              </Button>
              <Button
                loading={loading}
                htmlType="submit"
                style={{
                  fontWeight: 500,
                  color: "#fff",
                  background: "#2D9AFF",
                  border: "1px solid #2D9AFF",
                  borderRadius: 5
                }}
                color="#ED5A33"
              >
                Gửi
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Confirm;
