import React, { useContext, useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Row, Col, Input, Form, Button, message } from 'antd';
import Logo from './../../assets/logo-bg.png'
import Irandom from './../../assets/irandom.svg'
import Bgcapcha from './../../assets/bgcapcha.svg'
import { AuthContext } from './../../context/AuthProvider';
import background from "./../../assets/bg.jpeg";
import Scrolltop from "./../../assets/scrolltop.svg";
import Service from "./../../extra/getAPI";
import Helper from './../../extra/helper';

export default function Header() {
  const { setArrSearch, setAnimation, arrSearch } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [clickButton, setclickButton] = useState(false);
  const [publickey, setPublickey] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [captcha, setCaptcha] = useState(null);

  const getCaptcha = () => {
    const captcha = parseInt(Math.floor(Math.random() * 10000));
    if (String(captcha).length < 4) {
      getCaptcha();
    } else {
      setCaptcha(captcha);
    }
  }

  const CompareCaptcha = (values) => {
    const captchaForm = form.getFieldValue('captcha');
    if (parseInt(captchaForm) === captcha) {
      getSearch(values);
      getCaptcha();
      form.setFieldsValue({ 'captcha': '' });
    } else {
      message.warning('Mã xác thực không chính xác!');
      getCaptcha();
      form.setFieldsValue({ 'captcha': '' });
    }
  }

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (values.search && publickey) {
          CompareCaptcha(values);
        } else {
          setVariableCatch();
        }
      })
      .catch((err) => {
        console.error('err:', err);
      });
  };

  const getPublickey = () => {
    Service.apicall('GET', `api/v1/auth/public-key`).then((res) => {
      let data = res?.data
      if (res.status === 200) {
        setPublickey(data);
      }
    }).catch((err) => {
      setVariableCatch();
    }
    );
  }

  // khi có code trên url thì tự fill vào (tạm thời bỏ qua)
  useEffect(() => {
    const keyParams = Helper.getParamUrlByKey('code');
    if (keyParams) {
      getSearch({ search: '' });
    }
  }, [publickey]);

  const getSearch = (values) => {
    setclickButton(true);
    let data = {
      requestData: values.search,
      fullName: values.name
    }
    data = JSON.stringify(data);
    let encryptedData = Service.encryptMessage(data, publickey);
    Service.apicall('POST', `api/v1/orders/list`, encryptedData).then((res) => {
      let data = res?.data
      if (res?.data?.total > 0) {
        if (arrSearch?.length > 0) {
          setVariableCatch();
          setTimeout(() => {
            // window.scrollTo(0, 90);
            setclickButton(false);
            setArrSearch(data?.result);
            setAnimation(true);
          }, 1000);
        } else {
          window.scrollTo(0, 90);
          setclickButton(false);
          setArrSearch(data?.result);
          setAnimation(true);
          return
        }
      } else {
        setArrSearch([]);
        setclickButton(false);
        if (data?.errorCode === '003') {
          message.warning('Bạn đang thao tác quá nhanh. Vui lòng thử lại trong giây lát!');
          return;
        }
        setclickButton(false);
      }
    }).catch((err) => {
      setclickButton(false);
      setVariableCatch();
      setclickButton(false);
    }
    );
  }

  const setVariableCatch = () => {
    setclickButton(false);
    setAnimation(false);
    setTimeout(() => {
      setArrSearch(null);
    }, 1000);
  }

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    getCaptcha();
    const keyParams = Helper.getParamUrlByKey('code');
    window.addEventListener("scroll", handleScroll);
    getPublickey();
    if (keyParams) {
      form.setFieldsValue({ 'search': '' });
    };
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onFinish();
      event.preventDefault();
    }
  }


  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const css = {
    input: { color: '#3A3A3A', zIndex: 10, fontSize: 15, boxShadow: 'none', padding: '12px 10px', borderRadius: 9, width: '100%' },
    inputCapcha: { color: '#3A3A3A', zIndex: 10, fontSize: 14, boxShadow: 'none', padding: '10px 10px', borderRadius: 8, width: '100%' },
    FormItemsHolder: { width: "100%", borderRadius: 8, margin: '5px 0px', padding: 0 },
    button: { fontSize: 14, background: '#19447E', color: '#ffffff', width: '100%', textAlign: 'center', borderRadius: 8, fontWeight: 500, height: 50, border: '1px solid #8b91dd' },
    titleInput: { color: '#ffffff', textAlign: 'left', fontWeight: 600, marginTop: 5 }
  }

  return (
    <>
      <Row style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', overflow: 'hidden' }}>
        <Col onClick={ScrollToTop} className='SrollTop' style={{ cursor: 'pointer', position: 'fixed', right: '1%', bottom: '5%', zIndex: 99, opacity: 0.5, display: scrollPosition > 0 ? '' : 'none' }} xs={24}>
          <img style={{ width: 40 }} src={Scrolltop} alt="scroll" />
        </Col>
        <div style={{ width: '100%' }}>
          <Row style={{ background: 'linear-gradient(270deg, rgba(25, 68, 126, 0.9) 44.79%, rgba(25, 68, 126, 0) 100%)' }}>
            <Col xs='24' style={{ width: '100%', maxWidth: 1700, margin: '0px auto' }}>
              <Row style={{ padding: '1% 5%' }}>
                <Col xs={24} xl={16}>
                  <Animated animationIn="bounceInLeft" animationOut="zoomOutDown" animationInDuration={1500} animationOutDuration={1500} isVisible={true}>
                    <a href='https://globalcare.com.vn/'>
                      <img style={{ marginBottom: 20, marginTop: 8, background: '#ffffff', width: 150, padding: '8px 15px', borderRadius: 7 }} src={Logo} alt="Logo" />
                    </a>
                  </Animated>
                </Col>
                <Col xl={8} xs={24}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <Animated animationIn="bounceInRight" animationOut="zoomOutDown" animationInDuration={1500} animationOutDuration={1500} isVisible={true}>
                      <h4 style={{ fontSize: 29, fontWeight: 'bold', color: '#FFFFFF' }}>
                        Tra Cứu Thông Tin Bảo Hiểm
                      </h4>
                    </Animated>
                    <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={2000} isVisible={true}>
                      <Form
                        ref={formRef}
                        form={form}
                        onFinish={onFinish}
                        autoComplete='off'
                        style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 30, zIndex: 10 }}
                      >
                        <Row style={{ width: '100%' }}>
                          {/* <Col xs={0} xl={5}></Col> */}
                          <Col xs={24} xl={24}>
                            <div style={css.titleInput}>Họ và tên chủ hợp đồng/Số điện thoại</div>
                            <div id="search" style={{ width: '100%' }}>
                              <Form.Item
                                name="name"
                                rules={[{
                                  required: true,
                                  message: 'Vui lòng nhập đầy đủ thông tin.',
                                  // pattern: /^[a-zA-Z0-9\+]*$/
                                }]}
                                style={{ ...css.FormItemsHolder }}
                              >
                                <Input
                                  onInput={e => e.target.value = Helper.capitalize(e.target.value)}
                                  onKeyPress={handleKeyPress}
                                  style={{ ...css.input, textTransform: 'capitalize' }}
                                  allowClear={true}
                                  placeholder='Nhập họ và tên chủ hợp đồng/Số điện thoại'
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} xl={24}>
                            <div style={css.titleInput}>CMND/CCCD/Biển số xe/Số hợp đồng</div>
                            <div id="search" style={{ width: '100%' }}>
                              <Form.Item
                                name="search"
                                rules={[{
                                  required: true,
                                  message: 'Vui lòng nhập đầy đủ thông tin.',
                                  // pattern: /^[a-zA-Z0-9\+]*$/
                                }]}
                                style={css.FormItemsHolder}
                              >
                                <Input
                                  allowClear={true}
                                  onKeyPress={handleKeyPress}
                                  style={{ ...css.input, textTransform: 'capitalize' }}
                                  placeholder='Nhập CMND/CCCD/Biển số xe/Số hợp đồng'
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} xl={24}>
                            <div style={css.titleInput}>Mã captcha</div>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                              <div id="search" style={{ width: '100%', marginRight: 5 }}>
                                <Form.Item
                                  name="captcha"
                                  rules={[{
                                    required: true,
                                    message: 'Vui lòng nhập mã xác thực.',
                                  }]}
                                  style={css.FormItemsHolder}
                                >
                                  <Input
                                    allowClear={true}
                                    onKeyPress={handleKeyPress}
                                    type='text'
                                    style={{ ...css.inputCapcha }}
                                    placeholder='Mã xác thực'
                                  />
                                </Form.Item>
                              </div>
                              <div style={{ marginRight: 5, position: 'relative', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                                <img style={{ height: 44 }} src={Bgcapcha} alt="Logo" />
                                <div style={{ position: 'absolute', fontSize: 18, fontWeight: 'bold', fontFamily: 'Handschrift' }}><i>{captcha}</i></div>
                              </div>
                              <img onClick={() => getCaptcha()} style={{ width: "20px", marginTop: 16, cursor: 'pointer' }} src={Irandom} alt="Logo" />
                            </div>
                          </Col>
                          <Col xs={24} xl={24}>
                            <div style={{ width: '180px', margin: '14px auto' }}>
                              <Button
                                loading={clickButton}
                                disabled={clickButton}
                                className='buttoncustom'
                                onClick={() => {
                                  onFinish();
                                }} size="large" style={{ ...css.button, fontWeight: 'bold' }}>
                                Tra Cứu
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </Animated>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
}
