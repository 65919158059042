import React, { useState, useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import Service from './../extra/getAPI';
import * as config from './../extra/config';
import Helper from './../extra/helper';

export const AuthContext = React.createContext();
export default function AuthProvider({ children }) {
  const history = useHistory();
  const [arrSearch, setArrSearch] = useState(null);
  const [animation, setAnimation] = useState(false);
  const [data, setData] = useState({});

  return (
    <AuthContext.Provider value={{
      arrSearch, setArrSearch, animation, setAnimation,data, setData
    }}>
      {children}
    </AuthContext.Provider>
  );
}
