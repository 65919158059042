import React, { useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import Container from "./container";
export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Container />
      <Footer />
    </>
  );
}
