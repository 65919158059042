import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import Done from "./../../../assets/done.svg";

const Succes = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <Modal
        style={{ fontSize: 14 }}
        footer={false}
        closable={false}
        // width={}
        open={props.open}
        onCancel={props.handCancel}
        title={""}
      >
        <div style={{ textAlign: "center" }}>
          <img src={Done} style={{ width: 80 }} />
          <div style={{ textAlign: "center", fontSize: 18, fontWeight: 600 }}>
            Thành công
          </div>
          <div>
            Yêu cầu hủy của quý khách đã được gửi thành công, chúng tôi sẽ xử lý
            và phản hồi kết quả tới email của quý khách.
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
            }}
          >
            <Button
              onClick={() => {
                props.handCancel();
                window.location.reload();
              }}
              loading={false}
              style={{
                background: "#2D9AFF",
                color: "#fff",
                textAlign: "center",
                borderRadius: 7,
                height: 40,
                width: 100,
              }}
            >
              <b>Đóng</b>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Succes;
