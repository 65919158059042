//build dev
const server = 'https://g1-dev.globalcare.vn/';
const serverCore = 'https://core-dev.globalcare.vn';

//build beta
// const server = 'https://g1-beta.globalcare.vn/';
// const serverCore = 'https://core-beta.globalcare.vn';



// build live
// const server = 'https://g1.globalcare.vn/';
// const serverCore = 'https://core.globalcare.vn';


export {
    server,
    serverCore
};