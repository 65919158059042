import axios from 'axios';
import * as config from './config';
import { Base64 } from 'js-base64';
import {JSEncrypt} from 'jsencrypt';
import { message } from "antd";

async function apicall(method, url, data) {
  let token = localStorage.getItem('token');
  const res = await axios({
    method: method,
    url: `${config.server}${url}`,
    data: { "data": data },
    headers: {
      "Content-Type": "application/json",
    }
  });
  return res;
};

async function apicalCore(method, url, data) {
  try {
    const res = await axios({
      method: method,
      url: `${config.serverCore}${url}`,
      data: { data: Base64.encode(data) },
      headers: {
        "Content-Type": "application/json",
        // "Authorization": `bearer ${token}`,
      }
    });
    return res;
  } catch (error) {
    if (error.response) {
      message.error(
        {
          content: error.response.data.message,
          style: {
            width: "90%",
            margin: "25px auto",
          }
        },
        15
      );
    }
  }
};

function encryptMessage(message, publicKey) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
 
  return jsEncrypt.encrypt(message);
}


const variable = { apicall, encryptMessage,apicalCore}
export default variable;