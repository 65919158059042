import moment from "moment";

function _params(key) {
    let search = window.location.search;
    let arr = search.split('?')
    let params = new URLSearchParams(arr[1]);
    let value_params = params.get(`${key}`);
    if (value_params) {
        return value_params;
    }
    return null;
};

function getParamUrl() {
    let search = window.location.search;
    let arr = search.split('?')
    let params = new URLSearchParams(arr[1]);
    let token = params.get('token');
    return token;
};

function getParamUrlByKey(keyparame) {
    let search = window.location.search;
    let arr = search.split('?')
    let params = new URLSearchParams(arr[1]);
    let res;

    if (keyparame) {
        res = params.get(keyparame);
    }
    return res;
};

function _formatMoney(str) {
    if (str) {
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    return 0;
};

function _validPhone(phone1) {
    // carrier 1 là vietel, 2 là mobi, 3 là vina
    var phone = phone1.substr(0, 3);
    var viettel = /((086|096|097|098|032|033|034|035|036|037|038|039))/g;
    var mobi = /((089|090|093|070|071|072|076|077|078|079))/g;
    var vina = /((088|091|094|083|084|085|081|082))/g;
    var vietnamobile = /((092|056|058))/g;
    var gmobile = /((099|059))/g;
    var sFone = /((095))/g;

    if (phone.toString().length !== 10)
        return "Số điện thoại phải đủ 10 số";

    if (viettel.test(phone) || mobi.test(phone) || vina.test(phone) || vietnamobile.test(phone) || gmobile.test(phone) || sFone.test(phone)) {
        return '';
    } else {
        return 'Số điện thoại không đúng';
    }
};

// add effective date less than 1 year vungtaman
function _addEffectiveDate(year, number) {
    var time = year.split('/');
    var yearr = '';
    var month = '';
    var day = '';
    let resultAddMonth = '';

    if (number === 1) {
        resultAddMonth = moment(`${time[2]}-${time[1]}-${time[0]}`).add(12, 'M').format('YYYY-MM-DD');
    }
    else {
        resultAddMonth = moment(`${time[2]}-${time[1]}-${time[0]}`).add(number, 'M').format('YYYY-MM-DD');
    };

    day = resultAddMonth.split('-')[2];
    month = resultAddMonth.split('-')[1];
    yearr = resultAddMonth.split('-')[0];

    var c = day + '/' + month + '/' + yearr;
    // var c = yearr + '-' + month + '-' + day;

    return c;
};

function _getTPlatformParamUrl() {
    let search = window.location.search;
    let arr = search.split("?");
    let params = new URLSearchParams(arr[1]);
    let platform = params.get("platform");
    return platform;
};

function _formatDayNoHour(str) {
    if (str) {
        return moment(str).format('DD/MM/YYYY');
    }
    return '';
};

function _formatDay(str) {
    if (str) {
        return moment.utc(str).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
};

function _formatH(str) {
    if (str) {
        return moment(str).format('h:mm a');
    }
    return '';
};

function _renderData(value) {
    var data = value;
    var newdata = [];
    for (var i = 0; i < parseInt(data.length); i++) {
        var item = {
            insuredName: data[i]?.fullname,
            insuredPhone: data[i]?.phoneNumber,
            insuredDob: _formatDayNoHour(data[i]?.birthday),
            insuredEmail: data[i]?.email,
            insuredPrivateId: data[i]?.privateId
        };
        newdata.push(item);
    };
    return (newdata);
}

function _checkEndate(value) {
    var toDay = _formatDay(new Date());
    var endDate = _formatDay(value);
    // var data = moment(new Date(), 'DD/MM/YYYY').isAfter(moment(value, 'DD/MM/YYYY'));
    var data = moment(toDay, 'DD/MM/YYYY').isAfter(moment(endDate, 'DD/MM/YYYY'));
    return (data);
}

function capitalize(str) {
    if (!str) { return ''; }
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    };
    return splitStr.join(' ');
}
const variable = {
    _params,
    getParamUrl,
    getParamUrlByKey,
    _formatMoney,
    _addEffectiveDate,
    _getTPlatformParamUrl,
    _renderData,
    _formatDayNoHour,
    _formatH,
    _validPhone,
    _formatDay,
    _checkEndate,
    capitalize
};
export default variable;