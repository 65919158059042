import React, { useContext, useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Row, Col } from 'antd';
import LogoColor from './../../assets/logo-bg.png'
import store from "./../../assets/store.png";
import chplay from "./../../assets/chplay.png";
import zalo from "./../../assets/zalo.png";
import fb from "./../../assets/fb.png";
import youtube from "./../../assets/youtube.png";

export default function Footer() {
  return (
    <Animated animationIn="fadeInUp" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={2000} isVisible={true}>
      <div style={{ width: '100%', background: '#F0F7FF', color:'#FFFFFF !impotant'}}>
        <div style={{ width: '100%', maxWidth: 1500, margin: '0px auto' }}>
          <Row style={{ padding: '4% 5%', color: '#8a99b3' }}>
            <Col xl={6}>
              <div style={{ fontWeight: 600, fontSize: 18, color: '#19447F', marginBottom: 12, textTransform: 'capitalize' }}>
                Thông tin liên hệ
              </div>
              <a href='https://globalcare.com.vn/' >
                <img style={{ marginBottom: 12, width:'120px'}} src={LogoColor} alt="Logo" />
              </a>
              <div  style={{ marginBottom: 12 }}>
                <a className='colorFooter' target={'_blank'} href='https://www.google.com/maps/place/M%E1%BA%A7m+Non+%C3%81nh+D%C6%B0%C6%A1ng+Preschool+Sunshine/@10.7875489,106.7291507,21z/data=!4m8!1m2!7m1!2e1!3m4!1s0x317527538f791d01:0x711964a89bfe29d3!8m2!3d10.7881305!4d106.7294506'>
                  1/2 đường 33, Phường An Khánh,<br/> TP.Thủ Đức, TP.Hồ Chí Minh
                </a>
              </div>
              <div style={{ marginBottom: 12 }}>
                <a className='colorFooter' href="tel:1900292987">1900 29 29 87</a>
              </div>
              <div style={{ marginBottom: 14, textDecoration:'underline'}}>
                <a className='colorFooter' href="mailto: cskh@globalcare.vn">cskh@globalcare.vn</a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a className='colorFooter' href='https://zalo.me/1747508283619492630' target={'_blank'}>
                  <img style={{ width: "30px", marginBottom: 12, marginRight: 15 }} src={zalo} alt="Logo" />
                </a>
                <a className='colorFooter' href='https://www.facebook.com/GlobalCare.vn' target={'_blank'}>
                  <img style={{ width: "30px", marginBottom: 12, marginRight: 15 }} src={fb} alt="Logo" />
                </a>
                <a className='colorFooter' href='https://www.youtube.com/channel/UCVCpGwSPErtw3OyLQYwwulw' target={'_blank'}>
                  <img style={{ width: "37px", marginBottom: 12, marginRight: 15 }} src={youtube} alt="Logo" />
                </a>
              </div>
            </Col>
            <Col xl={14}>
              <Row>
                <Col xl={24}>
                  <div style={{ fontWeight: 600, fontSize: 18, color: '#19447F', marginBottom: 12, textTransform: 'capitalize' }}>
                    Tìm hiểu về Global Care
                  </div>
                </Col>
                <Col xl={8} xs={24}>
                  <div style={{ marginBottom: 12 }}>
                    <a  target={'_blank'} href='https://www.globalcare.com.vn' className='linkFooter colorFooter'>Trang chủ</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/ve-chung-toi' className='linkFooter colorFooter'>Giới thiệu</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/tin-tuc' className='linkFooter colorFooter'>Tin tức</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/lien-he' className='linkFooter colorFooter'>Liên hệ</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/tuyen-dung' className='linkFooter colorFooter'>Tuyển dụng</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/cong-tac-vien' className='linkFooter colorFooter'>Cộng tác viên</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/cau-hoi-thuong-gap' className='linkFooter colorFooter'>Câu hỏi thường gặp</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/tai-tai-lieu' className='linkFooter colorFooter'>Tải tài liệu</a>
                  </div>
                </Col>
                <Col xl={16}>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/khach-hang-ca-nhan' className='linkFooter colorFooter'>Khách hàng cá nhân</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/khach-hang-doanh-nghiep' className='linkFooter colorFooter'>Khách hàng doanh nghiệp</a>
                  </div>
                  {/* <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/' className='linkFooter'>Tra cứu thông tin bồi thường</a>
                  </div> */}
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/wp-content/uploads/2022/07/QUY-TRINH-GIAI-QUYET-KHIEU-NAI_website.pdf' className='linkFooter colorFooter'>Quy trình tiếp nhận phản ánh khiếu nại</a>
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    <a target={'_blank'} href='https://www.globalcare.com.vn/wp-content/uploads/2022/07/nghi-dinh-so-03_2021_nd-cp_15.01.2021-1.pdf' className='linkFooter colorFooter'>Nghị định 03/2021/ND-CP v/v chứng nhận bảo hiểm</a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={4} xs={24} style={{color:'#19447F !imp'}}>
              <div style={{ fontWeight: 600, fontSize: 18, color: '#19447F', marginBottom: 12 }}>
                Tải Ứng Dụng Gsale
              </div>
              <a target={'_blank'} href='https://apps.apple.com/vn/app/gsale/id1578454672'>
                <img style={{ width: '90%', marginBottom: '12px', maxWidth: 250}} src={store} alt="store" />
              </a>
              <a target={'_blank'} href='https://play.google.com/store/apps/details?id=vn.globalcare.isale&hl=vi'>
                <img style={{ width: '90%',  maxWidth: 250 }} src={chplay} alt="chplay" />
              </a>
              <div style={{ fontWeight: 600, fontSize: 18, color: '#19447F', marginTop: 12 }}>
                Tải Ứng Dụng BH365
              </div>
              <a target={'_blank'} href='https://apps.apple.com/vn/app/bh365/id1563171847'>
                <img style={{ width: '90%', marginTop: '12px', maxWidth: 250 }} src={store} alt="store" />
              </a>
              <a target={'_blank'} href='https://play.google.com/store/apps/details?id=vn.globalcare.bh365&hl=vi'>
                <img style={{ width: '90%', marginTop: '12px', maxWidth: 250 }} src={chplay} alt="chplay" />
              </a>
            </Col>
          </Row>
        </div>
        <Row style={{ background: '#1A437E', padding: '24px 5%', color: '#FFFFFF' }}>
          <Col xl={24}>
            <div style={{ textAlign: 'center', background: '#1A437E' }}>
              © 2022 Copyright by Global Care Ltd,. All rights reserved
            </div>
          </Col>
        </Row>
      </div>
    </Animated>
  );
}
